import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiCopy, FiSend } from 'react-icons/fi';
import { useModal } from '../../../../../../hooks/Modal';
import { Form } from '@unform/web';
import Input from '../../../../../../components/Forms/Input';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useSearch } from '../../../../../../hooks/Search';

interface ICreateModule {
  lineData: Record<string, any>;

}

export default function DuplicateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { addLoading, removeLoading } = useLoading();
  const { reloadSearchAll } = useSearch();
  const duplicate = async (data) => {


    if (!data?.project_id) {
      addToast({ title: 'Informe um projeto', type: 'info' })
      return;
    }

    addLoading({ title: 'Duplicando', key: 'loading' })

    const current = await api.get(`/events-pages/${lineData?._id?.toString()}`);

    const newData = { ...current?.data };
    delete newData._id;
    delete newData.createdAt;
    delete newData.updatedAt;

    newData.project_id = data?.project_id;


    try {



      await api.post(`/events-pages`, newData);
      removeModal('duplicate');

      removeLoading('loading')
      addToast({ title: 'Duplicado com sucesso!', type: 'success' })
      reloadSearchAll();
    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao duplicar', type: 'error' })
      removeLoading('loading')
      return;
    }

  }

  const handleSend = async (id: string): Promise<void> => {

    addModal({
      title: '', theme: 'whiteModalMedium', key: 'duplicate', content: <>
        <Form onSubmit={duplicate}>

          <Input name='project_id' placeholder='ID do Projeto' />

          <aside className='full-width row' style={{ margin: '10px auto' }}>
            <button className='greenButton2' type="submit">Salvar</button>
          </aside>
        </Form>

      </>
    })

  };

  const moduleUpdate = (id: string): JSX.Element => (
    id ? <button className="lineIcon" title="Duplicar" type="button" onClick={() => handleSend(id)}>
      <FiCopy />
    </button> : <></>
  );

  return moduleUpdate(lineData._id.toString());
}
