import styled from "styled-components";

export const Ticket = styled.div`
     flex-grow: 0;
    width: 100%;
    max-width: 250px;
 
    @media (max-width: 750px) {
        max-width: 320px;
  }
    border-radius: 1px;
 display: flex;
    flex-direction: column;
    color: #093366;
    align-items: center;
    border-radius: 10px;

    >div{
width: 100%;
        padding: 20px;
    background-color: #fff;
 
    border-radius: 1px;
    flex-direction: column;
    color: #093366;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    }

    h2{
        color: rgb(2,28,60);
    font-size: 30px;
    text-align: center;
 
    font-weight: 400;
    margin: 0;
   
    }

    svg{
        font-size: 18px;
        min-width: 20px;
        color: rgb(0,150,0) !important;
    }

    p{ max-width: 300px;
        width: 100%;
        color:  #555;
        font-size: 12px;
        
    }


`