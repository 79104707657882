import React, { useEffect, useState } from 'react';
import { FaCheck, FaCheckCircle, FaChevronDown, FaChevronRight, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { string } from 'yup';
import FadeInOnScroll from '../../../../../../components/FadeInOnScroll';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import { ParagraphHighlightUI, TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import { useAuth } from '../../../../../../hooks/Auth';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useModal } from '../../../../../../hooks/Modal';
import api from '../../../../../../services/api';
import { formatReal } from '../../../../../../utils/format';
import { scrollToID } from '../../../../../../utils/scrollTo';
import LeadCapture from '../../../ButtonLeadCapture';
import RenderPageList from '../../modules/RenderList';
import RenderPageImages from "../../modules/RenderPageImages";
import { ItemDiv, Container } from './styles';
function useProducts() {
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const loadProducts = async ({ project_id }) => {

    const products = await api.get(`/products-list/${project_id}`, { params: { limitItems: 10000 } });
    products?.data?.rows?.sort((a, b) => a?.price < b?.price ? -1 : a?.price > b?.price ? 1 : 0)
    setProducts(products?.data?.rows || []);

  }

  return { loadProducts, products };

}
const RenderListWithNoNumbers = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;
  const { configuration } = useConfiguration();
  const { addModal } = useModal();
  const { setActiveByModal, activeByModal } = useAuth();
  const checkLeadForm = (buttonInfo: Record<string, any>) => {
    setActiveByModal(true);
    addModal({ title: '', theme: 'whiteModalMedium', content: <LeadCapture redirect={buttonInfo?.redirect} coupon={buttonInfo?.coupon} /> });
    if (buttonInfo?.id_target) {
      scrollToID(buttonInfo?.id_target)
    }

  }


  return (
    <Container>
      <div className='column' style={{ position: 'relative', width: '100%', background: pageContent?.theme_data?.background || 'none' }}>

        {pageContent?.background_image ? <><div className="full-width  " style={{ position: 'absolute', zIndex: -1, opacity: 0.5, top: '0px', left: 0, right: 0, bottom: 0, background: `url("${urlWebsite}/${pageContent?.background_image}")`, backgroundSize: 'cover', backgroundAttachment: 'fixed', minHeight: '30vh' }} />  </> : <></>}


        <ContainerLineUI className=' column backgroundImagePreset gap-sm ' style={{ maxWidth: '1200px', background: 'none', alignItems: 'flex-start', flexWrap: 'wrap', padding: '50px 30px', paddingTop: '100px' }} >

          <h2 className='h2' dangerouslySetInnerHTML={{ __html: pageContent?.title?.toUpperCase() }} style={{ background: pageContent?.theme_data?.title_background, fontSize: '24px', margin: '15px auto', padding: '10px', color: pageContent?.theme_data?.title_color, textAlign: 'center' }} />
          {pageContent?.description ? <p className='whiteColor p' dangerouslySetInnerHTML={{ __html: pageContent?.description?.toUpperCase() }} style={{ fontSize: '24px', margin: '15px auto', padding: '10px', color: pageContent?.theme_data?.text_color }} /> : <></>}

          <div className='row ' style={{ flexWrap: 'wrap', gap: '10px', width: '100%', justifyContent: 'center', alignItems: 'stretch' }}>
            {pageContent?.data_list?.map((item, index) => {

              return <ItemDiv>

                <h2   >{item?.title?.toUpperCase()}</h2>
                <p dangerouslySetInnerHTML={{ __html: item?.text }} />
              </ItemDiv>

            })}
          </div>

        </ContainerLineUI>
      </div>
    </Container>
  );


}

export default RenderListWithNoNumbers;