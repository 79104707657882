import styled from "styled-components";

export const Container = styled.div`

width: 100%;

.h2{
    font-size: 24px;
    line-height: 30px;
    color: #fff !important;
    strong, p ,h2{
        color: #fff !important;  
    }
    @media (max-width: 750px) {
      
        font-size: 18px;
        line-height: 22px;
  }
}

.p{
   
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 750px) {
      
        font-size: 12px;
        line-height: 16px;
  }

  p{
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 750px) {
      
        font-size: 12px;
        line-height: 16px;
  }  
  }
 
}

`;

export const ItemDiv = styled.div`
    
    display: flex;
    flex-direction: column;
    position: relative;
    transition: 0.5s all;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

    transition: 0.5s all;
    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,10,73,1) 56%, rgba(0,21,99,1) 100%);
        backdrop-filter : blur(20px);
 
        border-radius: 10px;
        font-size: 14px;
        border: 1px solid #fff;
  color: #fff;
        padding: 30px;
        width: 100%;
        max-width:300px;
 
        width: 100%;


    &:hover{
        transform: scale(1.01);
>div{
    box-shadow: 0px 0px 25px  rgba(0,250,0,0.9) !important;
}
        >h2{
            border-color:  rgb(0,250,0) !important;
            
        }
    }

>div{
    transition: 0.5s all;
    align-self: flex-start;
    margin-right: -10px;
    font-weight: bold;
    background-color:  rgb(0,250,0) !important;
box-shadow: 0px 0px 15px  rgba(0,250,0,0.7) !important;
    backdrop-filter : blur(11px);
    font-size: 28px;
    color: rgb(2, 28, 60);
    display: flex ;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
h2{
    margin-bottom: 10px;
}
  
p, h2{
    color :  #fff !important;
    font-size: 14px;
}

`