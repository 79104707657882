import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { useModal } from '../../../../../../hooks/Modal';
import { Form } from '@unform/web';
import Input from '../../../../core/components/Forms/Input';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useSearch } from '../../../../../../hooks/Search';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { addLoading, removeLoading } = useLoading();
  const { reloadSearchAll } = useSearch();

  const handleSend = async ({ name, email }): Promise<void> => {
    addLoading({ title: 'Enviando', key: 'loading' })
    removeModal('modal')
    try {
      const response = await api.post(`/send-courtesy-mail/${lineData._id}`, { name, email });
      removeModal('modal')
      removeLoading('loading');
      addToast({ title: 'Enviado com sucesso!', type: 'success' });
      reloadSearchAll()

    }
    catch (err) {

      removeLoading('loading');
      handleApiErrors(err);
      addToast({ title: 'Erro ao enviar!', type: 'error' });
    }
  };

  const prepareSend = async (): Promise<void> => {


    addModal({
      title: '', theme: "whiteModalMedium", key: "modal", content: <Form initialData={{ name: lineData?.name, email: lineData?.email }} onSubmit={(data) => handleSend({ name: data.name, email: data?.email })}>

        <p style={{ textAlign: 'center', width: '100%' }}>Convite: <strong>{lineData?.title}</strong></p>
        <p style={{ textAlign: 'center', width: '100%' }}><strong>{lineData?.quantity} ingresso(s)</strong></p>
        <br />
        <Input name='name' placeholder='Nome do convidado' />
        <Input name='email' placeholder='E-mail' />
        <aside className='full-width column'>
          <button className='greenButton2'>Enviar</button>
        </aside>

      </Form>
    })
  };


  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" title="Enviar cortesia por e-mail" type="button" onClick={() => prepareSend()}>
      <FiSend />
    </button>
  );

  return moduleUpdate();
}
